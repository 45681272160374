import React from 'react';
import { Link } from 'react-router-dom';
import  useFirestore from '../Hooks/UseFirestore';

// CSS
import './CommentSummary.css';

const CommentSummary = () => {

  const { docs } = useFirestore('QuestionsCustomRequests');
  
  console.log(docs);

  return (
    <div className='CommentSummaryCont'>
      { docs && docs.map(doc => (
        <Link to={'/CommentDetail/' + doc.id} key={doc.id}>
          <div className='CommentCard'>
            <div className='CustomerCommentCont'>
              <p className='CustomerCommentTitle'>Customer Comment:</p>
              <p className='CustomerComment'>{doc.QuestionCustomRequest}</p>
            </div>
            <div className='CommentDateCont'>
              <p className='CommentDateTitle'>Comment Received:</p>
              <p className='CommentDate'>{new Date(doc.createdAt.seconds * 1000).toLocaleDateString("en-US")}</p>
            </div>
            <div className='CustomerFirstNameCont'>
              <p className='CustomerFirstNameTitle'>First Name:</p>
              <p className='CustomerFirstName'>{doc.FirstName}</p>
            </div>
            <div className='CustomerLastNameCont'>
              <p className='CustomerLastNameTitle'>Last Name:</p>
              <p className='CustomerLastName'>{doc.LastName}</p>
            </div>
            <div className='CustomerEmailCont'>
              <p className='CustomerEmailTitle'>E-mail:</p>
              <p className='CustomerEmail'>{doc.Email}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )

}

export default CommentSummary;