import { useState, useEffect } from 'react';
import { commentsQuestionsFirestore } from '../../Firebase/FirebaseConfig';

const useComment = (collection, id) => {

  const [doc, setDoc] = useState([]);

  useEffect(() => {
    const unsub = commentsQuestionsFirestore.collection(collection)
      .doc(id)
      .onSnapshot((snap) => {
        let document = [];
        document.push({ ...snap.data(), commentId: snap.id });
        setDoc(document);
      });

    return () => unsub();

  }, [collection, id])

  return { doc };
}

export default useComment;