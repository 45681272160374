import { timestamp } from '../../Firebase/FirebaseConfig';

export const ContactFormInputChange = (id, value) => {
  return (dispatch) => {
    dispatch({ type: 'INPUT_FIELD_CHANGED', id, value });
  }
}

export const AddComment = () => {
  return (dispatch, getState, { getFirebase }) => {
    const comments = getState().Comments;
    const firestore = getFirebase().firestore();
    const createdAt = timestamp();
    firestore
      .collection('QuestionsCustomRequests')
      .add({
        Email: comments.contactForm.Email,
        FirstName: comments.contactForm.FirstName,
        LastName: comments.contactForm.LastName,
        QuestionCustomRequest: comments.contactForm.CustomOrder,
        createdAt: createdAt
      }).then(() => {
        dispatch({type: 'COMMENT_ADDED'});
      }).catch((err) => {
        dispatch({type: 'ERROR_COMMENT_ADDED', err})
      });
  }
}

export const CloseContactFormSubmissionModal = () => {
  return (dispatch) => {
    dispatch({ type: 'CONTACT_FORM_SUBMISSION_MODAL_CLOSED' })
  }
}