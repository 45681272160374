import { combineReducers } from 'redux';

// REDUCERS
import WindowSizeScrollPositionReducer from './WindowSizeScrollPositionReducer';
import MobileMenuIconReducer from './MobileMenu_IconReducer';
import AddComment from './AddCommentReducer';
import LoginOut from './LoginOutReducer';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

const RootReducer = combineReducers({
  WindowSizeScrollPosition: WindowSizeScrollPositionReducer,
  MobileMenu: MobileMenuIconReducer,
  Comments: AddComment,
  LoginOut: LoginOut,
  firebase: firebaseReducer,
  firestore: firestoreReducer
});

export default RootReducer;