export const LoginFormInputChange = (id, value) => {
  return (dispatch) => {
    dispatch({ type: 'LOGIN_INPUT_CHANGED', id, value });
  }
}

export const LogMeIn = () => {
  return (dispatch, getState, { getFirebase }) => {
    const credentials = getState().LoginOut.credentials;
    const firebase = getFirebase();
    firebase.auth().signInWithEmailAndPassword(
      credentials.UserName,
      credentials.Password
    ).then(() => {
      dispatch({ type: 'LOGIN_SUCCESS' })
    }).catch((err) => {
      dispatch({ type: 'LOGIN_ERROR', err })
    })
  }
}

export const LogMeOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().signOut().then(() => {
      dispatch({ type: 'LOG_OUT_SUCCESSFUL'})
    });
  }
}