import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faFacebookSquare, faYoutube, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

// Images
import Logo from './DFFLogoWhite.svg';

// CSS
import './Footer.css';

class Footer extends Component {
  render() {
    return (
      <div className='Footer'>
        <div className='FooterCont'>
          <div className="FooterLogoCont">
            <p className="FooterLogoName">Dragon's Fire Forge</p>
            <img src={Logo} alt='Circular logo with a dragon in flight breathing fire onto an anvil.' className='FooterLogo' />
          </div>
          <div className='FooterIconCont'>
            <a href='mailto:dragonsfireforge2020@gmail.com'>
              <FontAwesomeIcon icon={faEnvelope} className='EmailIcon FooterIcon' />
            </a>
            <a href='https://www.facebook.com/DragonsFireForgeUS' target='_blank' rel='noopener noreferrer'>
              <FontAwesomeIcon icon={faFacebookSquare} className='FacebookIcon FooterIcon' />
            </a>
            <a href='https://www.youtube.com/channel/UCRZ_2alMqnIMpU80Ndnr-qA' target='_blank' rel='noopener noreferrer'>
              <FontAwesomeIcon icon={faYoutube} className='YoutubeIcon FooterIcon' />
            </a>
            <a href='https://twitter.com/Dragons_F_Forge' target='_blank' rel='noopener noreferrer'>
              <FontAwesomeIcon icon={faTwitterSquare} className='TwitterIcon FooterIcon' />
            </a>
          </div>
        </div>
        <Link to='/Login' className="FooterCopyWrite"><p>© 2020 Dragon's Fire Forge. All Rights Reserved.</p></Link>
      </div>
    );
  }
}

export default Footer;