import React, { Component } from 'react';
import { connect } from 'react-redux';

// COMPONENTS
import Modal from './Modal';

// ACTIONS
import { AddComment, ContactFormInputChange } from '../../Store/Actions/AddCommentActions';

// CSS
import './ContactForm.css';

class ContactForm extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    this.props.form_input_changed(id, value);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.add_comment();
  }

  render() {

    const { contactFormInputInfo, contactFormSubmitted } = this.props;

    const OpenContactFormSubmittedModal = contactFormSubmitted ? <Modal /> : null;

    return (
      <div className='CustomOrdersCont'>
        { OpenContactFormSubmittedModal }
        <div className='CustomOrdersTitleCont'>
          <h1 className="CustomOrdersTitle">ASK US</h1>
          <h1 className="CustomOrdersTitle2">ANYTHING</h1>
          <h2 className="CustomOrdersTitleDes">Custom orders, Requests or Questions</h2>
        </div>
        <form className="CutomOrderRequestQuestionForm" onSubmit={this.handleSubmit}>
          <div className="FormSec FirstName">
            <input type='text' placeholder='First Name' id='FirstName' onChange={this.handleChange} required minLength='2' maxLength='20' value={contactFormInputInfo.FirstName} />
          </div>
          <div className="FormSec LastName">
            <input type='text' placeholder='Last Name' id='LastName' onChange={this.handleChange} required minLength='2' maxLength='20' value={contactFormInputInfo.LastName} />
          </div>
          <div className="FormSec Email">
            <input type='email' placeholder='Email' id='Email' onChange={this.handleChange} required minLength='5' pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" value={contactFormInputInfo.Email} />
          </div>
          <div className="FormSec CustomOrder">
            <textarea placeholder='Custom orders, requests  or questions...' id='CustomOrder' onChange={this.handleChange} required minLength='10' value={contactFormInputInfo.CustomOrder} />
          </div>
          <div className='FormSec FormSubmit'>
            <button type='submit' className="Submit">Submit</button>
          </div>
        </form>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    form_input_changed: (id, value) => dispatch(ContactFormInputChange(id, value)),
    add_comment: () => dispatch(AddComment())
  }
}

const mapStateToProps = (state) => {
  return {
    contactFormInputInfo: state.Comments.contactForm,
    contactFormSubmitted: state.Comments.contactFormSubmitted
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);