import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// IMAGES
import hammericon from './HammerIcon.svg';

//CSS
import './About.css';

class About extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='About'>
        <div className='AboutUsHeroSect'>
          <h1 className='AboutUsTitle'>ABOUT US</h1>
          <FontAwesomeIcon icon={faChevronDown} size='2x' className='HeroScrollDown' />
        </div>
        <div className='AboutUsDescSect'>
          <div className='AboutUsOutterGrid'>
            <div className='AboutUsInnerGrid'>
              <div className='AboutUsDescTitleCont'>
                <h1 className='AboutUsDescTitle'>We strive to create one of a kind decorative items that will last a lifetime.</h1>
                <p className='AboutUsDesc'>
                  Dragon's Fire Forge is a partnership between Dave and Brandon that together with craftsmanship has lead them to create high quality one of a kind decorative items that can be handed down for generations to come.
                </p>
              </div>
              <div className='OurGoalsCont'>
                <h1 className='OurGoalsTitle'>Our Goals</h1>
                <p className='OurGoalsDesc'>
                  Our goal is to create high quality decorative items that last generations. We want to continually improve upon our craftsmanship and our products. We want to give our customers the best service possible by being honest and communicating quickly.
                </p>
              </div>
            </div>
            <div className='HammerIconCont'>
              <img src={hammericon} alt='Artistic rendering of a hammer used to forge steel inside of a circle drawn by a paint brush.' className='HammerIcon' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;