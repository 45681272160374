import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// ACTIONS
import { UpdateWindowWidth } from '../../Store/Actions/WindowSizeScrollPositionActions';
import { UpdateWindowHeight } from '../../Store/Actions/WindowSizeScrollPositionActions';
import { UpdateScrollPosition } from '../../Store/Actions/WindowSizeScrollPositionActions';
import { mobileMenuIconClickHandler } from '../../Store/Actions/MobileMenu_IconActions';
import { LogMeOut } from '../../Store/Actions/LoginOutActions';

//Images
import Logo from './DFFLogoWhite.svg';

//CSS
import './NavBar.css';

class NavBar extends Component {

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.updateScrollPosition();
    window.addEventListener('scroll', this.updateScrollPosition);
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.updateScrollPosition);
  }

  updateWindowDimensions = () => {
    this.props.UpdateWindowWidth(window.innerWidth);
    this.props.UpdateWindowHeight(window.innerHeight);
  }

  updateScrollPosition = () => {
    this.props.UpdateScrollPosition(window.scrollY);
  }

  logUserOut = () => {
    this.props.LogOut();
    this.props.mobileMenuIconClicked();
  }

  render() {

    const { WindowWidth, ScrollY, MobileMenuOpen, MobileMenuIconOpen, mobileMenuIconClicked, auth } = this.props;

    let mobileHamburgerClass = 'hamburger';

    let mobileMenuClass = 'mobileMenu';

    let scrolledNavBar = ScrollY > 0 ? 'NavBar scrolled' : 'NavBar';

    if(MobileMenuIconOpen) {
      mobileHamburgerClass = 'hamburger active';
    }

    if(MobileMenuOpen) {
      mobileMenuClass = 'mobileMenu open';
    }

    return (
      <nav className={scrolledNavBar}>        
        {
          MobileMenuOpen
            ? <Link to='/' onClick={mobileMenuIconClicked}>
                <div>
                  <img src={Logo} className='Logo' alt='Circular logo with a dragon in flight breathing fire onto an anvil.' />
                  <h1 className='LogoName'>Dragon's Fire Forge</h1>
                </div>
              </Link>
            : <Link to='/'>
                <div>
                  <img src={Logo} className='Logo' alt='Circular logo with a dragon in flight breathing fire onto an anvil.' />
                  <h1 className='LogoName'>Dragon's Fire Forge</h1>
                </div>
              </Link>
        }
        {
          WindowWidth < 850
            ?
              <div onClick={mobileMenuIconClicked} className={mobileHamburgerClass}>
                <span></span>
              </div>
            : auth.uid
              ? <div className='NavLinksCont'>
                  <ul className='NavLinks'>
                    <li><Link to='/About'>About</Link></li>
                    <li><a href='https://www.etsy.com/shop/DragonsFireForgeUS' target='_blank' rel='noopener noreferrer'>Shop</a></li>
                    <li><Link to='/Contact'>Contact</Link></li>
                    <li><Link to='/Dashboard'>Dashboard</Link></li>
                    <li onClick={this.logUserOut}>Log out</li>
                  </ul>
                </div>
              : <div className='NavLinksCont'>
                  <ul className='NavLinks'>
                    <li><Link to='/About'>About</Link></li>
                    <li><a href='https://www.etsy.com/shop/DragonsFireForgeUS' target='_blank' rel='noopener noreferrer'>Shop</a></li>
                    <li><Link to='/Contact'>Contact</Link></li>
                  </ul>
                </div> 
        }
        {
          WindowWidth < 850
            ?
              auth.uid
                ?
                  <div className={mobileMenuClass}>
                    <ul className='mobileNavLinks'>
                      <li><Link to='/About' onClick={mobileMenuIconClicked}>About</Link></li>
                      <li><a href='https://www.etsy.com/shop/DragonsFireForgeUS' target='_blank' rel='noopener noreferrer' onClick={mobileMenuIconClicked}>Shop</a></li>
                      <li><Link to='/Contact' onClick={mobileMenuIconClicked}>Contact</Link></li>
                      <li><Link to='/Dashboard' onClick={mobileMenuIconClicked}>Dashboard</Link></li>
                      <li onClick={this.logUserOut}>Log out</li>
                    </ul>
                  </div>
                :
                  <div className={mobileMenuClass}>
                    <ul className='mobileNavLinks'>
                      <li><Link to='/About' onClick={mobileMenuIconClicked}>About</Link></li>
                      <li><a href='https://www.etsy.com/shop/DragonsFireForgeUS' target='_blank' rel='noopener noreferrer' onClick={mobileMenuIconClicked}>Shop</a></li>
                      <li><Link to='/Contact' onClick={mobileMenuIconClicked}>Contact</Link></li>
                    </ul>
                  </div>
            :
              null
        }
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    UpdateWindowWidth: (windowWidth) => dispatch(UpdateWindowWidth(windowWidth)),
    UpdateWindowHeight: (windowHeight) => dispatch(UpdateWindowHeight(windowHeight)),
    UpdateScrollPosition: (scrollPosition) => dispatch(UpdateScrollPosition(scrollPosition)),
    mobileMenuIconClicked: () => dispatch(mobileMenuIconClickHandler()),
    LogOut: () => dispatch(LogMeOut())
  }
}

const mapStateToProps = (state) => {
  return {
    WindowWidth: state.WindowSizeScrollPosition.windowWidth,
    ScrollY: state.WindowSizeScrollPosition.scrollY,
    MobileMenuOpen: state.MobileMenu.mobileMenuOpen,
    MobileMenuIconOpen: state.MobileMenu.mobileMenuIconOpen,
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);