import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

// ACTIONS
import { LoginFormInputChange, LogMeIn } from '../../Store/Actions/LoginOutActions';

// CSS
import './Login.css';

class Login extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    this.props.login_form_input_changed(id, value);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.LogMeIn();
  }

  render() {

    const { login_error, login_form_input_info, auth } = this.props;

    if(auth.uid) return <Redirect to='/Dashboard' />

    return (
      <div className='LoginCont'>
        <div className='LoginFormCont'>
          <h1 className='LoginTitle'>Login</h1>
          <form className='LoginForm' onSubmit={this.handleSubmit}>
            <input type='email' placeholder='User name' id='UserName' onChange={this.handleChange} value={login_form_input_info.UserName} minLength='5' pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?" required />
            <input type='password' placeholder='Password' id='Password' onChange={this.handleChange} value={login_form_input_info.Password} required />
            <button type='submit' className='SubmitLogin'>Login</button>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    login_error: state.LoginOut.ERROR_Login,
    login_form_input_info: state.LoginOut.credentials,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login_form_input_changed: (id, value) => dispatch(LoginFormInputChange(id, value)),
    LogMeIn: () => dispatch(LogMeIn())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);