import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDvlTx2Z179u1McTpuVKo-UpJLnHE23FQ4",
  authDomain: "dragons-fire-forge.firebaseapp.com",
  databaseURL: "https://dragons-fire-forge.firebaseio.com",
  projectId: "dragons-fire-forge",
  storageBucket: "dragons-fire-forge.appspot.com",
  messagingSenderId: "9776738414",
  appId: "1:9776738414:web:3641115992ad042637367f",
  measurementId: "G-DPSNRDXWH8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const commentsQuestionsFirestore = firebase.firestore();

export default firebase;
export { timestamp, commentsQuestionsFirestore };