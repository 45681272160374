import React from 'react';
import useComment from '../Hooks/useComment';

// CSS
import './CommentDetail.css';

const CommentDetail = ({ commentId }) => {

  const { doc } = useComment('QuestionsCustomRequests', commentId);

  console.log(doc);

  return (
    <div className='CommentDetailCard'>
      { doc && doc.map(doc => (
        <div className='CommentDetailCont' key={doc.commentId}>
          <div className='CommentDetailDateCont'>
            <p className='CommentDetailDateTitle'>Date Posted: <span>{new Date(doc.createdAt.seconds * 1000).toLocaleDateString("en-US")}</span></p>
          </div>
          <div className='CommentDetailNameCont'>
            <p className='CommentDetailNameTitle'>Name:</p>
            <p className='CommentDetailName'>{doc.FirstName} {doc.LastName}</p>
          </div>
          <div className='CommentDetailEmailCont'>
            <p className='CommentDetailEmailTitle'>Email:</p>
            <p className='CommentDetailEmail'>{doc.Email}</p>
          </div>
          <div className='CommentDetailCommentCont'>
            <p className='CommentDetailCommentTitle'>Comment:</p>
            <p className='CommentDetailComment'>{doc.QuestionCustomRequest}</p>
          </div>
          <button className='AddNoteToComment'>Add Note</button>
          <button className='DeleteNote'>Delete</button>
        </div>
      ))}
    </div>
  )
}

export default CommentDetail;