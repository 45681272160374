const initState = {
  mobileMenuOpen: false,
  mobileMenuIconOpen: false
};

const MobileMenuIconReducer = (state = initState, action) => {
  switch (action.type) {
    case 'MOBILE_MENU_ICON_CLICKED':
      return {
        ...state,
        mobileMenuIconOpen: !state.mobileMenuIconOpen,
        mobileMenuOpen: !state.mobileMenuOpen
      }
    default:
      return state;
  }
}

export default MobileMenuIconReducer;