const initState = {
  contactForm: {
    FirstName: '',
    LastName: '',
    Email: '',
    CustomOrder: ''
  },
  contactFormSubmitted: false,
  ERRORContactForm: false
  
};

const AddComment = (state = initState, action) => {
  switch (action.type) {
    case 'INPUT_FIELD_CHANGED':
      return {
        ...state,
        contactForm: {
          ...state.contactForm,
          [action.id]:action.value
        }
      }
    case 'COMMENT_ADDED':
      console.log('comment recieved!!!');
      return {
        ...state,
        contactForm: {
          FirstName: '',
          LastName: '',
          Email: '',
          CustomOrder: ''
        },
        contactFormSubmitted: true
      }
    case 'ERROR_COMMENT_ADDED':
      console.log('ERROR_COMMENT_ADDED', action.err);
      return {
        ...state,
        ERRORContactForm: action.err
      }
    case 'CONTACT_FORM_SUBMISSION_MODAL_CLOSED':
      return {
        ...state,
        contactFormSubmitted: false
      }
    default:
      return state;
  }
}

export default AddComment;