import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// COMPONENTS
import CommentDetail from './CommentDetail';

// CSS
import './CommentDetailSummary.css';

class CommentDetailSummary extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  
  render() {

    const { commentId, auth } = this.props;

    if(!auth.uid) return <Redirect to='/Login' />

    return (
      <div className='CommentDetailSummaryCont'>
        <CommentDetail commentId={commentId} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  const id = ownProps.match.params.id;

  return {
    commentId: id,
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps, null)(CommentDetailSummary);