import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

//COMPONENTS
import NavBar from './Components/NavBar/NavBar';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Login from './Components/Login/Login';
import Dashboard from './Components/Dashboard/Dashboard';
import CommentDetailSummary from './Components/Dashboard/CommentDetailSummary';
import Footer from './Components/Footer/Footer';

// CSS
import './App.css';

class App extends Component {
  
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <NavBar />
          <Route exact path='/' component={Home} />
          <Route path='/About' component={About} />
          <Route path='/Contact' component={Contact} />
          <Route path='/Login' component={Login} />
          <Route path='/Dashboard' component={Dashboard} />
          <Route path='/CommentDetail/:id' component={CommentDetailSummary} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;