import React, { Component } from 'react';

// COMPONENTS
import ContactForm from './ContactForm';

// CSS
import './Contact.css';

class Contact extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='Contact'>
        <ContactForm />
      </div>
    )
  }
}

export default Contact;