export const UpdateWindowWidth = (windowWidth) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_WINDOW_WIDTH', windowWidth });
  }
}

export const UpdateWindowHeight = (windowHeight) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_WINDOW_HEIGHT', windowHeight });
  }
}

export const UpdateScrollPosition = (scrollPosition) => {
  return (dispatch, getState) => {
    dispatch({ type: 'UPDATE_SCROLL_POSITION', scrollPosition });
  }
}