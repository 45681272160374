const initState = {
  credentials: {
    UserName: '',
    Password: ''
  },
  ERROR_Login: null
};

const LoginOut = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_INPUT_CHANGED':
      return {
        ...state,
        credentials: {
          ...state.credentials,
          [action.id]: action.value
        }
      }
    case 'LOGIN_SUCCESS':
      console.log('successfully logged in!');
      return {
        ...state,
        credentials: {
          ...state.credentials,
          UserName: '',
          Password: ''
        }
      }
    case 'LOGIN_ERROR':
      console.log(action.err);
      return {
        ...state,
        ERROR_Login: action.err
      }
    case 'LOG_OUT_SUCCESSFUL':
      console.log('successfully logged out!');
      return state;
    default:
      return state;
  }
}

export default LoginOut;