import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// COMPONENTS
import CommentSummary from './CommentSummary';


// CSS
import './Dashboard.css'



class Dashboard extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {

    const { auth } = this.props;

    if(!auth.uid) return <Redirect to='/Login' />
    return (
      <div className='DashboardCont'>
        <CommentSummary />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps, null)(Dashboard)