import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// COMPONENTS
import ContactForm from '../Contact/ContactForm';

//Images
import Makers from './fire-iron-embers-hammer.jpg';

//CSS
import './Home.css';

class Home extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='Home'>
        {/* HERO CONTAINER */}
        <div className='HeroCont'>
          <div className='HeroTitleCont'>
            <h1 className='HeroTitle'>HAND FORGED</h1>
            <p className='HeroTitleDesc'>Decorative Items</p>
          </div>
          <FontAwesomeIcon icon={faChevronDown} size='2x' className='HeroScrollDown' />
        </div>
        {/* MAKERS CONTAINER */}
        <div className='MakersCont'>
          <div className='MeetMakersCont'>
            <img src={Makers} alt='Brandon and Dave forging steel' className='BrandonDave' />
            <h1 className='MakersTitle'>Meet Your Makers</h1>
            <h1 className='MakersTitle2'>at</h1>
            <h1 className='MakersTitle3'>Dragon's Fire Forge</h1>
            <p className='AboutMakers'>
              Brandon and Dave have taken their passion for forging and created Dragon's Fire Forge.
              Their partnership together with craftsmanship has lead them to create high quality one of a kind decorative items that will last a lifetime.
              Let them put their passion and craftsmanship to work for you and your project.
            </p>
            <Link to='/About' className='MeetMakersBtn'>About the Forge</Link>
          </div>
        </div>
        {/* CUSTOM ORDERS CONTAINER */}
        <div className="CustomOrdersSection">
          <ContactForm />
        </div>
      </div>
    );
  }
}

export default Home;