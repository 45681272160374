const initState = {
  windowWidth: 0,
  windowHeight: 0,
  scrollY: 10,
};

const WindowSizeScrollPositionReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_WINDOW_WIDTH':
      return {
        ...state,
        windowWidth: action.windowWidth
      }
    case 'UPDATE_WINDOW_HEIGHT':
      return {
        ...state,
        windowHeight: action.windowHeight
      }
    case 'UPDATE_SCROLL_POSITION':
      return {
        ...state,
        scrollY: action.scrollPosition
      }
    default:
      return state;
  }
}

export default WindowSizeScrollPositionReducer;