import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

//  ACTIONS
import { CloseContactFormSubmissionModal } from '../../Store/Actions/AddCommentActions';

// CSS
import './Modal.css';

class Modal extends Component {

  CloseThisModal = () => {
    this.props.closeModal();
  }

  render() {
    return (
      <div className='SubmissionModal' onClick={this.CloseThisModal}>
        <div className='ModalInfoCont'>
          <FontAwesomeIcon icon={faTimes} size='2x' className='CloseModal' onClick={this.CloseThisModal} />
          <p className='ModalInfo1'>Your contact information has been sent.</p>
          <p className='ModalInfo2'>We will contact you as soon as possible.</p>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: () => dispatch(CloseContactFormSubmissionModal())
  }
}

export default connect(null, mapDispatchToProps)(Modal);